import {
  Box,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Tabs,
  Tab,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const EventEdit = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentValues, setCurrentValues] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const [eventResponse, dropdownResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/events/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/dropdowns/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        const event = eventResponse.data;
        if (event.date) {
          event.date = format(parseISO(event.date), "yyyy-MM-dd");
        }

        const eventTypeOptions = dropdownResponse.data.filter(
          (option) => option.type === "eventtype"
        );

        setEventTypeOptions(eventTypeOptions);

        // Validate initial event type value
        if (
          event.type &&
          !eventTypeOptions.some((option) => option.display_text === event.type)
        ) {
          setEventData((prevData) => ({
            ...prevData,
            type: "",
          }));
        } else {
          setEventData(event);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, instance]);

  const validationSchema = yup.object({
    name: yup.string().required("Event Name is required"),
    type: yup.string().required("Event Type is required"),
    date: yup.date().required("Event Date is required"),
    description: yup.string().required("Description is required"),
  });

  const handleFormSubmit = (values) => {
    setOpenConfirm(true);
    setCurrentValues(values);
  };

  const confirmSave = async () => {
    if (!currentValues) {
      enqueueSnackbar("No data to save, please check the form fields.", {
        variant: "error",
      });
      return;
    }

    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.put(`${apiUrl}/api/events/${id}`, currentValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar("Event updated successfully.", {
        variant: "success",
      });
      navigate("/events");
    } catch (error) {
      enqueueSnackbar("Failed to update event.", { variant: "error" });
      console.error("Update error:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="EDIT EVENT"
          subtitle="Update Event Details"
        />
      </Box>

      <Box sx={{ width: "100%", mt: 5, mb: 6 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab label="Event Details" />
        </Tabs>
      </Box>

      {selectedTab === 0 && eventData && (
        <Box sx={{ width: "60%", margin: "auto" }}>
          <Formik
            enableReinitialize
            initialValues={eventData}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Event Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    required
                    label="Event Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <InputLabel
                    id="event-type-label"
                    size="small"
                    sx={{
                      textAlign: "right",
                      width: "100%",
                    }}
                  >
                    Event Type
                  </InputLabel>
                  <Select
                    labelId="event-type-label"
                    id="type"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.type && Boolean(errors.type)}
                    helperText={touched.type && errors.type}
                    sx={{ gridColumn: "span 1" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {eventTypeOptions.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.display_text}
                      >
                        {option.display_text}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    fullWidth
                    required
                    label="Event Date"
                    name="date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.date && Boolean(errors.date)}
                    helperText={touched.date && errors.date}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    required
                    label="Description"
                    name="description"
                    multiline
                    minRows={3}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                <Box
                  m="20px 0 0 0"
                  display="flex"
                  justifyContent="flex-end"
                  gap="10px"
                >
                  <Button
                    color="grey"
                    variant="contained"
                    onClick={() => navigate("/events")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirm(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmSave}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EventEdit;
