import React from "react";
import { Box, Button, Typography, Link } from "@mui/material";

const LoginPage = ({ handleLogin }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
      textAlign="center"
    >
      <Box
        top={20}
        left={20}
      >
        <Box>
          <img
            alt="otlogo"
            width="200px"
            height="200px"
            src={`../../assets/otlogo.png`}
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
        </Box>
      </Box>

      <Typography
        variant="h2"
        gutterBottom
      >
        Nau mai ki Oromahoe Trust
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
      >
        Shares and Grants Register
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        sx={{ mt: 2, mb: 4 }}
      >
        Sign In
      </Button>

      <Box
        position="absolute"
        top={20}
        right={20}
        display="flex"
        alignItems="center"
      >
        <Link
          href="#"
          underline="hover"
          mr={2}
        >
          Oromahoe Trust website
        </Link>
        <Link
          href="#"
          underline="hover"
          mr={2}
        >
          Contact us
        </Link>
      </Box>
    </Box>
  );
};

export default LoginPage;
