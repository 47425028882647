import {
  Box,
  IconButton,
  TextField,
  FormLabel,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import { DataGrid } from "@mui/x-data-grid";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const EventView = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { instance } = useMsal();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [associatedShareholders, setAssociatedShareholders] = useState([]);
  const [allShareholders, setAllShareholders] = useState([]);
  const [selectedShareholder, setSelectedShareholder] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const [
          eventResponse,
          dropdownResponse,
          shareholderResponse,
          allShareholdersResponse,
        ] = await Promise.all([
          axios.get(`${apiUrl}/api/events/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/dropdowns/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/shareholder-event/event/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/shareholders`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        const event = eventResponse.data;
        if (event.date) {
          event.date = format(parseISO(event.date), "yyyy-MM-dd");
        }

        const eventTypeOptions = dropdownResponse.data.filter(
          (option) => option.type === "eventtype"
        );

        setEventTypeOptions(eventTypeOptions);
        setEventData(event);

        setAssociatedShareholders(shareholderResponse.data);
        setAllShareholders(allShareholdersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, instance]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEdit = () => {
    navigate(`/events/edit/${id}`);
  };

  const handleClickOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  const handleDelete = async () => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.delete(`${apiUrl}/api/events/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar("Event deleted successfully.", {
        variant: "success",
      });
      navigate("/events");
      handleClose();
    } catch (error) {
      enqueueSnackbar("Failed to delete event.", { variant: "error" });
      handleClose();
    }
  };

  const handleAddShareholder = async () => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.post(
        `${apiUrl}/api/shareholder-event`,
        { event_id: id, shareholder_id: selectedShareholder },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const addedShareholder = allShareholders.find(
        (shareholder) => shareholder.id === selectedShareholder
      );

      enqueueSnackbar("Shareholder added successfully.", {
        variant: "success",
      });
      setAssociatedShareholders((prevShareholders) => [
        ...prevShareholders,
        addedShareholder,
      ]);
    } catch (error) {
      enqueueSnackbar("Failed to add shareholder.", { variant: "error" });
    }
  };

  const handleRemoveShareholder = async (shareholderId) => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.delete(`${apiUrl}/api/shareholder-event`, {
        data: { event_id: id, shareholder_id: shareholderId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar("Shareholder removed successfully.", {
        variant: "success",
      });
      setAssociatedShareholders((prevShareholders) =>
        prevShareholders.filter(
          (shareholder) => shareholder.id !== shareholderId
        )
      );
    } catch (error) {
      enqueueSnackbar("Failed to remove shareholder.", { variant: "error" });
    }
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="VIEW EVENT"
          subtitle="View Event Details"
        />
      </Box>

      <Box sx={{ width: "100%", mt: 5, mb: 6 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab label="Event Details" />
          <Tab label="Shareholders" />
        </Tabs>
      </Box>

      {selectedTab === 0 && eventData && (
        <Box sx={{ width: "60%", margin: "auto" }}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gridColumn: "span 4",
              }}
            >
              <IconButton
                style={{ color: theme.palette.edit.main }}
                onClick={handleEdit}
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton
                style={{ color: theme.palette.error.main }}
                onClick={handleClickOpen}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Box>

            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Event Details
            </FormLabel>
            <TextField
              fullWidth
              label="Event Name"
              value={eventData.name}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Event Type"
              value={eventData.type}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 1", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Event Date"
              type="date"
              value={eventData.date || ""}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Description"
              value={eventData.description}
              InputProps={{
                readOnly: true,
              }}
              multiline
              minRows={3}
              sx={{ gridColumn: "span 4", bgcolor: "#e4eeef" }}
            />
          </Box>
        </Box>
      )}

      {selectedTab === 1 && (
        <Box
          m="20px 0 0 0"
          height="75vh"
        >
          <Autocomplete
            options={allShareholders}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            onChange={(event, newValue) => {
              setSelectedShareholder(newValue ? newValue.id : "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Shareholder"
                variant="outlined"
              />
            )}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleAddShareholder}
            disabled={!selectedShareholder}
            sx={{ mb: 2 }}
          >
            Add Shareholder
          </Button>

          <DataGrid
            rows={associatedShareholders}
            columns={[
              { field: "first_name", headerName: "First Name", width: 150 },
              { field: "last_name", headerName: "Last Name", width: 150 },
              { field: "email", headerName: "Email", width: 200 },
              { field: "phone", headerName: "Phone", width: 150 },
              {
                field: "actions",
                headerName: "",
                width: 100,
                renderCell: (params) => (
                  <IconButton
                    style={{ color: theme.palette.error.main }}
                    onClick={() => handleRemoveShareholder(params.row.id)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                ),
              },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">WARNING!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDelete}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EventView;
