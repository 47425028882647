import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens - ctrl-k then ctrl-g

// https://colorhunt.co/palette/22283131363f76abaeeeeeee

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#d1d0ce",
          200: "#a3a19c",
          300: "#75716b",
          400: "#474239",
          500: "#191308",
          600: "#140f06",
          700: "#0f0b05",
          800: "#0a0803",
          900: "#050402",
        },
        primary: {
          100: "#e4eeef",
          200: "#c8dddf",
          300: "#adcdce",
          400: "#91bcbe",
          500: "#76abae",
          600: "#5e898b",
          700: "#476768",
          800: "#2f4446",
          900: "#182223",
        },
        greenAccent: {
          100: "#cde1d8",
          200: "#9ac4b0",
          300: "#68a689",
          400: "#358961",
          500: "#036b3a",
          600: "#02562e",
          700: "#024023",
          800: "#012b17",
          900: "#01150c",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e5f1",
          200: "#c2cbe2",
          300: "#a4b1d4",
          400: "#8597c5",
          500: "#677db7",
          600: "#526492",
          700: "#3e4b6e",
          800: "#293249",
          900: "#151925",
        },
      }
    : {
        grey: {
          100: "#050402",
          200: "#0a0803",
          300: "#0f0b05",
          400: "#140f06",
          500: "#191308",
          600: "#d1d0ce", // Manually changed
          700: "#75716b",
          800: "#a3a19c",
          900: "#d1d0ce",
        },
        primary: {
          100: "#182223",
          200: "#2f4446",
          300: "#476768",
          400: "#5e898b",
          500: "#76abae",
          600: "#91bcbe",
          700: "#adcdce",
          800: "#c8dddf",
          900: "#e4eeef",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151925",
          200: "#293249",
          300: "#3e4b6e",
          400: "#526492",
          500: "#677db7",
          600: "#8597c5",
          700: "#a4b1d4",
          800: "#c2cbe2",
          900: "#e1e5f1",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.greenAccent[300],
            },
            secondary: {
              main: colors.greenAccent[300],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[900],
            },
            edit: {
              main: colors.blueAccent[500],
            },
            delete: {
              main: colors.redAccent[500],
            },
            success: {
              main: colors.greenAccent[500],
            },
            error: {
              main: colors.redAccent[500],
            },
            warning: {
              main: colors.grey[500],
            },
            info: {
              main: colors.blueAccent[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.greenAccent[300],
            },
            secondary: {
              main: colors.greenAccent[300],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc", // manually set
            },
            edit: {
              main: colors.blueAccent[500],
            },
            delete: {
              main: colors.redAccent[500],
            },
            success: {
              main: colors.greenAccent[500],
            },
            error: {
              main: colors.redAccent[500],
            },
            warning: {
              main: colors.grey[500],
            },
            info: {
              main: colors.blueAccent[500],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans 3", "sans-serif"].join(","),
      fontSize: 14,
      h1: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
