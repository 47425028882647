import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";

const Events = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { instance } = useMsal();

  const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;
        const eventResult = await axios.get(`${apiUrl}/api/events`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const formattedData = eventResult.data.map((event) => {
          let formattedDate = "";
          if (event.date) {
            try {
              const parsedDate = parseISO(event.date);
              if (!isNaN(parsedDate)) {
                formattedDate = format(parsedDate, "yyyy-MM-dd");
              }
            } catch (error) {
              console.error("Invalid date format:", event.date);
            }
          }
          return {
            ...event,
            date: formattedDate,
          };
        });

        setRows(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [instance]);

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCreateNew = () => {
    navigate("/events/create");
  };

  const handleEdit = (id) => {
    navigate(`/events/edit/${id}`);
  };

  const handleDelete = async () => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;
      await axios.delete(`${apiUrl}/api/events/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      enqueueSnackbar("Event deleted successfully.", {
        variant: "success",
      });
      setRows(rows.filter((row) => row.id !== selectedId));
      handleClose();
    } catch (error) {
      enqueueSnackbar("Failed to delete event.", { variant: "error" });
      handleClose();
    }
  };

  const handleRowClick = (params) => {
    navigate(`/events/view/${params.id}`);
  };

  const columns = [
    {
      field: "name",
      headerName: "Event Name",
      width: 250,
      hideable: false,
      cellClassName: "name-column--cell",
    },
    { field: "type", headerName: "Event Type", width: 150, hideable: false },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (params) => {
        try {
          const parsedDate = parseISO(params.value);
          if (!isNaN(parsedDate)) {
            return format(parsedDate, "dd-MM-yyyy");
          }
        } catch (error) {
          console.error("Invalid date format:", params.value);
        }
        return "";
      },
    },
    { field: "description", headerName: "Description", width: 400 },
    {
      field: "Actions",
      headerName: "",
      sortable: false,
      flex: 1,
      align: "right",
      headerAlign: "right",
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params) => (
        <div>
          <IconButton
            style={{ color: theme.palette.edit.main }}
            onClick={(e) => {
              e.stopPropagation(); // Stop the row click event
              handleEdit(params.id);
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            style={{ color: theme.palette.error.main }}
            onClick={(e) => {
              e.stopPropagation(); // Stop the row click event
              handleClickOpen(params.id);
            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="EVENTS"
          subtitle="Manage Events"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="right"
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateNew}
          sx={{ marginRight: "10px" }}
        >
          New +
        </Button>
      </Box>
      <Box
        m="20px 0 0 0"
        height="75vh"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                address_id: false,
              },
            },
          }}
          onRowClick={handleRowClick} // Add onRowClick to handle row clicks
        />
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">WARNING!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this event?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleDelete}
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Events;
