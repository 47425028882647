import {
  Box,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import Autocomplete from "@mui/material/Autocomplete";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const ShareholderView = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { instance } = useMsal();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const [genderOptions, setGenderOptions] = useState([]);
  const [sharecategoryOptions, setShareCategoryOptions] = useState([]);
  const [shareholderData, setShareholderData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [associatedEvents, setAssociatedEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const [
          shareholderResponse,
          dropdownResponse,
          eventResponse,
          allEventsResponse,
        ] = await Promise.all([
          axios.get(`${apiUrl}/api/shareholders/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/dropdowns/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/shareholder-event/shareholder/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/events`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        const shareholder = shareholderResponse.data;
        if (shareholder.birth_date) {
          shareholder.birth_date = format(
            parseISO(shareholder.birth_date),
            "yyyy-MM-dd"
          );
        }
        if (shareholder.death_date) {
          shareholder.death_date = format(
            parseISO(shareholder.death_date),
            "yyyy-MM-dd"
          );
        }
        setShareholderData(shareholder);

        const genderOptions = dropdownResponse.data.filter(
          (option) => option.type === "gender"
        );
        const shareCategoryOptions = dropdownResponse.data.filter(
          (option) => option.type === "sharecategory"
        );

        setGenderOptions(genderOptions);
        setShareCategoryOptions(shareCategoryOptions);

        setAssociatedEvents(
          eventResponse.data.map((event) => ({
            ...event,
            date: format(parseISO(event.date), "dd-MM-yyyy"),
          }))
        );
        setAllEvents(allEventsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, instance]);

  const getShareCategoryDisplayText = () => {
    const category = sharecategoryOptions.find(
      (option) => option.value === shareholderData.sharecategory
    );
    return category ? category.display_text : "";
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEdit = () => {
    navigate(`/shareholders/edit/${id}`);
  };

  const handleClickOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  const handleDelete = async () => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.delete(`${apiUrl}/api/shareholders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar("Shareholder deleted successfully.", {
        variant: "success",
      });
      navigate("/shareholders");
      handleClose();
    } catch (error) {
      enqueueSnackbar("Failed to delete shareholder.", { variant: "error" });
      handleClose();
    }
  };

  const handleAddEvent = async () => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.post(
        `${apiUrl}/api/shareholder-event`,
        { shareholder_id: id, event_id: selectedEvent },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const addedEvent = allEvents.find((event) => event.id === selectedEvent);
      if (addedEvent) {
        addedEvent.date = format(parseISO(addedEvent.date), "dd-MM-yyyy");
      }

      enqueueSnackbar("Event added successfully.", { variant: "success" });
      setAssociatedEvents((prevEvents) => [...prevEvents, addedEvent]);
    } catch (error) {
      enqueueSnackbar("Failed to add event.", { variant: "error" });
    }
  };

  const handleRemoveEvent = async (eventId) => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.delete(`${apiUrl}/api/shareholder-event`, {
        data: { shareholder_id: id, event_id: eventId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar("Event removed successfully.", { variant: "success" });
      setAssociatedEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventId)
      );
    } catch (error) {
      enqueueSnackbar("Failed to remove event.", { variant: "error" });
    }
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="VIEW SHAREHOLDER"
          subtitle="View Shareholder Details"
        />
      </Box>

      <Box sx={{ width: "100%", mt: 5, mb: 6 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab label="Shareholder Details" />
          <Tab label="Events" />
          <Tab label="Documents" />
        </Tabs>
      </Box>

      {selectedTab === 0 && shareholderData && (
        <Box sx={{ width: "60%", margin: "auto" }}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gridColumn: "span 4",
              }}
            >
              <IconButton
                style={{ color: theme.palette.edit.main }}
                onClick={handleEdit}
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton
                style={{ color: theme.palette.error.main }}
                onClick={handleClickOpen}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Box>

            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Personal Details
            </FormLabel>
            <TextField
              fullWidth
              label="First Name"
              value={shareholderData.first_name}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Middle Name"
              value={shareholderData.middle_name || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Last Name"
              value={shareholderData.last_name}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Given Names"
              value={shareholderData.given_names || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 4", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Date of birth"
              type="date"
              value={shareholderData.birth_date || ""}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Gender"
              value={shareholderData.gender || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 1", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Address
            </FormLabel>
            <TextField
              fullWidth
              label="Address Line 1"
              value={shareholderData.street_address || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Address Line 2"
              value={shareholderData.street_address2 || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="City / Town"
              value={shareholderData.city_town || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Postal Code"
              value={shareholderData.postal_code || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Country"
              value={shareholderData.country || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Contact Details
            </FormLabel>
            <TextField
              fullWidth
              label="Email"
              value={shareholderData.email || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Phone"
              value={shareholderData.phone || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Whakapapa
            </FormLabel>
            <TextField
              fullWidth
              label="Iwi"
              value={shareholderData.iwi || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Hapu"
              value={shareholderData.hapu || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Marae"
              value={shareholderData.marae || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Shareholder Details
            </FormLabel>
            <TextField
              fullWidth
              label="Shareholder Number"
              value={shareholderData.mlc_shareholderno || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Shareholder Name"
              value={shareholderData.mlc_shareholdername || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Shareholding"
              value={shareholderData.mlc_shareholding || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Shareholder Category"
              value={getShareCategoryDisplayText()}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 1", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Ownership Type"
              value={shareholderData.mlc_ownershiptype || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Authority"
              value={shareholderData.mlc_authority || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Ratio"
              value={shareholderData.mlc_ratio || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Beneficiary Details
            </FormLabel>
            <FormGroup sx={{ gridColumn: "span 2" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shareholderData.is_beneficialowneroutright}
                    disabled
                  />
                }
                label="Beneficial owner outright"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shareholderData.is_beneficialownerofwhanautrust}
                    disabled
                  />
                }
                label="Beneficial owner of whanau trust"
              />
            </FormGroup>
            <FormGroup sx={{ gridColumn: "span 2" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shareholderData.is_beneficaryofwhanautrust}
                    disabled
                  />
                }
                label="Beneficary of whanau trust"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shareholderData.is_beneficaryofbeneficialowner}
                    disabled
                  />
                }
                label="Beneficary of beneficial owner"
              />
            </FormGroup>
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Financial Details
            </FormLabel>
            <TextField
              fullWidth
              label="IRD Number"
              value={shareholderData.ird_number || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Bank"
              value={shareholderData.bank || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Bank Account Name"
              value={shareholderData.bank_accountname || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Bank Account Number"
              value={shareholderData.bank_accountnumber || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Death details if applicable
            </FormLabel>
            <TextField
              fullWidth
              label="Date of death"
              type="date"
              value={shareholderData.death_date || ""}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareholderData.is_deceased}
                  disabled
                />
              }
              label="Deceased"
              sx={{ gridColumn: "span 2" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Other
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareholderData.is_staff}
                  disabled
                />
              }
              label="Staff Member"
              sx={{ gridColumn: "span 2" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareholderData.is_trustee}
                  disabled
                />
              }
              label="Trustee"
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              label="Notes"
              value={shareholderData.notes || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              minRows={3}
              sx={{ gridColumn: "span 4", bgcolor: "#e4eeef" }}
            />
          </Box>
        </Box>
      )}

      {selectedTab === 1 && (
        <Box
          m="20px 0 0 0"
          height="75vh"
        >
          <Autocomplete
            options={allEvents}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSelectedEvent(newValue ? newValue.id : "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Event"
                variant="outlined"
              />
            )}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleAddEvent}
            disabled={!selectedEvent}
            sx={{ mb: 2 }}
          >
            Add Event
          </Button>

          <DataGrid
            rows={associatedEvents}
            columns={[
              { field: "name", headerName: "Event Name", width: 200 },
              { field: "type", headerName: "Event Type", width: 150 },
              { field: "date", headerName: "Date", width: 150 },
              { field: "description", headerName: "Description", width: 400 },
              {
                field: "actions",
                headerName: "",
                width: 100,
                renderCell: (params) => (
                  <IconButton
                    style={{ color: theme.palette.error.main }}
                    onClick={() => handleRemoveEvent(params.row.id)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                ),
              },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">WARNING!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this shareholder?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDelete}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShareholderView;
