import {
  Box,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const EventCreate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [eventTypeOptions, setEventTypeOptions] = useState([]);

  // Populate event type dropdown
  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;
        const result = await axios.get(`${apiUrl}/api/dropdowns/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEventTypeOptions(
          result.data.filter((option) => option.type === "eventtype")
        );
      } catch (error) {
        console.error("Error fetching event type options:", error);
      }
    };

    fetchData();
  }, [instance]);

  const initialValues = {
    name: "",
    type: "",
    date: "",
    description: "",
  };

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    type: yup.string().required("Event Type is required"),
    date: yup.date().required("Date is required"),
    description: yup.string().required("Description is required"),
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;
      await axios.post(`${apiUrl}/api/events`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      enqueueSnackbar("Event created successfully.", { variant: "success" });
      resetForm();
      navigate("/events");
    } catch (error) {
      enqueueSnackbar("Failed to create event.", { variant: "error" });
      console.error("Create event error:", error);
    }
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="CREATE EVENT"
          subtitle="Create a New Event"
        />
      </Box>

      <Box
        sx={{
          width: "60%",
          margin: "auto", // Centers the grid horizontally
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  required
                  label="Event Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                />
                <InputLabel
                  id="event-type-label"
                  size="small"
                  sx={{
                    textAlign: "right",
                    width: "100%",
                  }}
                >
                  Event Type
                </InputLabel>
                <Select
                  labelId="event-type-label"
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.type && Boolean(errors.type)}
                  helperText={touched.type && errors.type}
                  sx={{ gridColumn: "span 1" }}
                >
                  {eventTypeOptions.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.display_text}
                    >
                      {option.display_text}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  fullWidth
                  required
                  label="Event Date"
                  name="date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.date && Boolean(errors.date)}
                  helperText={touched.date && errors.date}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  required
                  label="Description"
                  name="description"
                  multiline
                  minRows={3}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              <Box
                m="20px 0 0 0"
                display="flex"
                justifyContent="flex-end"
                gap="10px"
              >
                <Button
                  color="grey"
                  variant="contained"
                  onClick={() => navigate("/events")}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                >
                  Create Event
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EventCreate;
