import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const loginRequest = {
  scopes: [`${process.env.REACT_APP_API_IDENTIFIER}/access_as_user`],
};

const msalInstance = new PublicClientApplication(msalConfig);

const login = async () => {
  try {
    const loginResponse = await msalInstance.loginPopup(loginRequest);
    msalInstance.setActiveAccount(loginResponse.account);
  } catch (error) {
    console.error("Login failed: ", error);
  }
};

export { msalInstance, loginRequest, login };
