import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./config/authConfig";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Shareholders from "./scenes/shareholders";
import ShareholdersView from "./scenes/shareholders/view";
import ShareholderCreate from "./scenes/shareholders/create";
import ShareholderEdit from "./scenes/shareholders/edit";
import Grants from "./scenes/grants";
import GrantRecipientView from "./scenes/grants/view";
import GrantCreate from "./scenes/grants/create";
import GrantRecipientEdit from "./scenes/grants/edit";
import Events from "./scenes/events";
import EventView from "./scenes/events/view";
import EventEdit from "./scenes/events/edit";
import EventCreate from "./scenes/events/create";
import AuthCallback from "./scenes/auth/AuthCallback";
import LoginPage from "./scenes/auth/LoginPage";

function App() {
  const [theme, colorMode] = useMode();
  const { instance } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = () => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    checkAuthentication();
  }, [instance]);

  const handleLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
      instance.setActiveAccount(loginResponse.account); // Set the active account after login
      setIsAuthenticated(true); // Update state to reflect authentication
      navigate("/");
    } catch (e) {
      console.error(e);
    }
  };

  const handleLogout = () => {
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
    setIsAuthenticated(false); // Update state to reflect logout
  };

  const getSnackbarStyle = () => ({
    success: { backgroundColor: theme.palette.success.main },
    error: { backgroundColor: theme.palette.error.main },
    warning: { backgroundColor: theme.palette.warning.main },
    info: { backgroundColor: theme.palette.info.main },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            variantSuccess: getSnackbarStyle().success,
            variantError: getSnackbarStyle().error,
            variantWarning: getSnackbarStyle().warning,
            variantInfo: getSnackbarStyle().info,
          }}
        >
          <CssBaseline />
          <div className="app">
            {isAuthenticated && <Sidebar handleLogout={handleLogout} />}
            <main className="content">
              <Topbar
                isAuthenticated={isAuthenticated}
                handleLogin={handleLogin}
              />
              <Routes>
                <Route
                  path="/"
                  element={
                    isAuthenticated ? <Dashboard /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/shareholders"
                  element={
                    isAuthenticated ? (
                      <Shareholders />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/shareholders/view/:id"
                  element={
                    isAuthenticated ? (
                      <ShareholdersView />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/shareholders/create"
                  element={
                    isAuthenticated ? (
                      <ShareholderCreate />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/shareholders/edit/:id"
                  element={
                    isAuthenticated ? (
                      <ShareholderEdit />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/grants"
                  element={
                    isAuthenticated ? <Grants /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/grants/view/:id"
                  element={
                    isAuthenticated ? (
                      <GrantRecipientView />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/grants/create"
                  element={
                    isAuthenticated ? <GrantCreate /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/grants/edit/:id"
                  element={
                    isAuthenticated ? (
                      <GrantRecipientEdit />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/events"
                  element={
                    isAuthenticated ? <Events /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/events/view/:id"
                  element={
                    isAuthenticated ? <EventView /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/events/create"
                  element={
                    isAuthenticated ? <EventCreate /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/events/edit/:id"
                  element={
                    isAuthenticated ? <EventEdit /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/auth-callback"
                  element={<AuthCallback />}
                />
                <Route
                  path="/login"
                  element={<LoginPage handleLogin={handleLogin} />}
                />
              </Routes>
            </main>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
