import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormLabel,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const GrantRecipientEdit = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [genderOptions, setGenderOptions] = useState([]);
  const [grantRecipientData, setGrantRecipientData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentValues, setCurrentValues] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const [grantRecipientResponse, dropdownResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/grantrecipients/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/dropdowns/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        const grantRecipient = grantRecipientResponse.data;
        if (grantRecipient.birth_date) {
          grantRecipient.birth_date = format(
            parseISO(grantRecipient.birth_date),
            "yyyy-MM-dd"
          );
        }

        if (grantRecipient.address_id) {
          const addrResponse = await axios.get(
            `${apiUrl}/api/addresses/${grantRecipient.address_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const address = addrResponse.data;
          setGrantRecipientData({
            ...grantRecipient,
            ...address,
            id: grantRecipient.id,
            address_id: grantRecipient.address_id,
            consider_workingnorthland:
              grantRecipient.consider_workingnorthland || false,
            consider_researchngapuhiissues:
              grantRecipient.consider_researchngapuhiissues || false,
            consider_postgradstudy:
              grantRecipient.consider_postgradstudy || false,
          });
        } else {
          setGrantRecipientData({
            ...grantRecipient,
            address_id: null,
            consider_workingnorthland:
              grantRecipient.consider_workingnorthland || false,
            consider_researchngapuhiissues:
              grantRecipient.consider_researchngapuhiissues || false,
            consider_postgradstudy:
              grantRecipient.consider_postgradstudy || false,
          });
        }

        const genderOptions = dropdownResponse.data.filter(
          (option) => option.type === "gender"
        );

        setGenderOptions(genderOptions);

        // Validate initial gender value
        if (
          grantRecipient.gender &&
          !genderOptions.some(
            (option) => option.display_text === grantRecipient.gender
          )
        ) {
          setGrantRecipientData((prevData) => ({
            ...prevData,
            gender: "",
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, instance]);

  const validationSchema = yup.object({
    first_name: yup.string().required("First name is required"),
    middle_name: yup.string().nullable(),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email address").nullable(),
    phone: yup.string().nullable(),
    gender: yup.string().nullable(),
    birth_date: yup.date().nullable().notRequired(),
    student_id: yup.string().nullable(),
    ird_number: yup.string().nullable(),
    bank: yup.string().nullable(),
    bank_accountname: yup.string().nullable(),
    bank_accountnumber: yup.string().nullable(),
    iwi: yup.string().nullable(),
    hapu: yup.string().nullable(),
    marae: yup.string().nullable(),
    consider_workingnorthland: yup.boolean(),
    consider_researchngapuhiissues: yup.boolean(),
    consider_postgradstudy: yup.boolean(),
    areas_of_interest: yup.string().nullable(),
    street_address: yup.string().nullable(),
    street_address2: yup.string().nullable(),
    city_town: yup.string().nullable(),
    area_region: yup.string().nullable(),
    postal_code: yup.string().nullable(),
    country: yup.string().nullable(),
  });

  const handleFormSubmit = (values) => {
    setOpenConfirm(true);
    setCurrentValues(values);
  };

  const confirmSave = async () => {
    if (!currentValues) {
      enqueueSnackbar("No data to save, please check the form fields.", {
        variant: "error",
      });
      return;
    }

    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.put(`${apiUrl}/api/grantrecipients/${id}`, currentValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar("Grant recipient updated successfully.", {
        variant: "success",
      });
      navigate("/grants");
    } catch (error) {
      enqueueSnackbar("Failed to update.", { variant: "error" });
      console.error("Update error:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="EDIT GRANT RECIPIENT"
          subtitle="Update Grant Recipient Details"
        />
      </Box>

      <Box sx={{ width: "100%", mt: 5, mb: 6 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab label="Grant Recipient Details" />
        </Tabs>
      </Box>

      {selectedTab === 0 && grantRecipientData && (
        <Box
          sx={{
            width: "60%",
            margin: "auto",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={grantRecipientData}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Personal Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    required
                    label="First Name"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.first_name && Boolean(errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Middle Name"
                    name="middle_name"
                    value={values.middle_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.middle_name && Boolean(errors.middle_name)}
                    helperText={touched.middle_name && errors.middle_name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    required
                    label="Last Name"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.last_name && Boolean(errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Date of birth"
                    name="birth_date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.birth_date || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.birth_date && Boolean(errors.birth_date)}
                    helperText={touched.birth_date && errors.birth_date}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <InputLabel
                    id="gender-label"
                    size="small"
                    sx={{
                      textAlign: "right",
                      width: "100%",
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.gender && Boolean(errors.gender)}
                    helperText={touched.gender && errors.gender}
                    sx={{ gridColumn: "span 1" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {genderOptions.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.display_text}
                      >
                        {option.display_text}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Address
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    name="street_address"
                    value={values.street_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.street_address && Boolean(errors.street_address)
                    }
                    helperText={touched.street_address && errors.street_address}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    name="street_address2"
                    value={values.street_address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.street_address2 && Boolean(errors.street_address2)
                    }
                    helperText={
                      touched.street_address2 && errors.street_address2
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="City / Town"
                    name="city_town"
                    value={values.city_town}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city_town && Boolean(errors.city_town)}
                    helperText={touched.city_town && errors.city_town}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Postal Code"
                    name="postal_code"
                    value={values.postal_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.postal_code && Boolean(errors.postal_code)}
                    helperText={touched.postal_code && errors.postal_code}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.country && Boolean(errors.country)}
                    helperText={touched.country && errors.country}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Contact Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Financial Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="IRD Number"
                    name="ird_number"
                    value={values.ird_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ird_number && Boolean(errors.ird_number)}
                    helperText={touched.ird_number && errors.ird_number}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Bank"
                    name="bank"
                    value={values.bank}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.bank && Boolean(errors.bank)}
                    helperText={touched.bank && errors.bank}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Bank Account Name"
                    name="bank_accountname"
                    value={values.bank_accountname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.bank_accountname &&
                      Boolean(errors.bank_accountname)
                    }
                    helperText={
                      touched.bank_accountname && errors.bank_accountname
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Bank Account Number"
                    name="bank_accountnumber"
                    value={values.bank_accountnumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.bank_accountnumber &&
                      Boolean(errors.bank_accountnumber)
                    }
                    helperText={
                      touched.bank_accountnumber && errors.bank_accountnumber
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Whakapapa
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Iwi"
                    name="iwi"
                    value={values.iwi}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.iwi && Boolean(errors.iwi)}
                    helperText={touched.iwi && errors.iwi}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Hapu"
                    name="hapu"
                    value={values.hapu}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.hapu && Boolean(errors.hapu)}
                    helperText={touched.hapu && errors.hapu}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Marae"
                    name="marae"
                    value={values.marae}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.marae && Boolean(errors.marae)}
                    helperText={touched.marae && errors.marae}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Student Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Student ID"
                    name="student_id"
                    value={values.student_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.student_id && Boolean(errors.student_id)}
                    helperText={touched.student_id && errors.student_id}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormGroup sx={{ gridColumn: "span 2" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.consider_workingnorthland}
                          onChange={handleChange}
                          name="consider_workingnorthland"
                        />
                      }
                      label="Consider working in Northland"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.consider_researchngapuhiissues}
                          onChange={handleChange}
                          name="consider_researchngapuhiissues"
                        />
                      }
                      label="Consider researching Ngāpuhi issues"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.consider_postgradstudy}
                          onChange={handleChange}
                          name="consider_postgradstudy"
                        />
                      }
                      label="Consider postgraduate study"
                    />
                  </FormGroup>
                  <TextField
                    fullWidth
                    label="Areas of Interest"
                    name="areas_of_interest"
                    multiline
                    minRows={3}
                    value={values.areas_of_interest}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.areas_of_interest &&
                      Boolean(errors.areas_of_interest)
                    }
                    helperText={
                      touched.areas_of_interest && errors.areas_of_interest
                    }
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                <Box
                  m="20px 0 0 0"
                  display="flex"
                  justifyContent="flex-end"
                  gap="10px"
                >
                  <Button
                    color="grey"
                    variant="contained"
                    onClick={() => navigate("/grants")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirm(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmSave}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GrantRecipientEdit;
