import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const Grants = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { instance } = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;
        const result = await axios.get(`${apiUrl}/api/grantrecipients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRows(result.data);
      } catch (error) {
        console.error("Error fetching grants:", error);
      }
    };

    fetchData();
  }, [instance]);

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCreateNew = () => {
    navigate("/grants/create");
  };

  const handleEdit = (id) => {
    navigate(`/grants/edit/${id}`);
  };

  const handleDelete = async () => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;
      await axios.delete(`${apiUrl}/api/grantrecipients/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      enqueueSnackbar("Grant deleted successfully.", {
        variant: "success",
      });
      setRows(rows.filter((row) => row.id !== selectedId));
      handleClose();
    } catch (error) {
      enqueueSnackbar("Failed to delete grant.", { variant: "error" });
      handleClose();
    }
  };

  const handleRowClick = (params) => {
    navigate(`/grants/view/${params.id}`);
  };

  const columns = [
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      hideable: false,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
      hideable: false,
    },
    { field: "given_names", headerName: "Given Names", width: 150 },
    { field: "phone", headerName: "Phone Number", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "Actions",
      headerName: "",
      sortable: false,
      flex: 1,
      align: "right",
      headerAlign: "right",
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params) => (
        <div>
          <IconButton
            style={{ color: theme.palette.edit.main }}
            onClick={(e) => {
              e.stopPropagation(); // Stop the row click event
              handleEdit(params.id);
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            style={{ color: theme.palette.error.main }}
            onClick={(e) => {
              e.stopPropagation(); // Stop the row click event
              handleClickOpen(params.id);
            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="GRANTS"
          subtitle="Manage Grants"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="right"
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreateNew}
          sx={{ marginRight: "10px" }}
        >
          New +
        </Button>
      </Box>
      <Box
        m="20px 0 0 0"
        height="75vh"
      >
        <DataGrid
          // checkboxSelection
          rows={rows}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                given_names: false,
              },
            },
          }}
          onRowClick={handleRowClick} // Add onRowClick to handle row clicks
        />
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">WARNING!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this grant recipient?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleDelete}
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Grants;
