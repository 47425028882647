import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

const AuthCallback = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]); // Set the active account after redirect
      navigate("/"); // Redirect to the home page or another desired route
    }
  }, [instance, navigate]);

  return <div>Loading...</div>; // Or any loading indicator
};

export default AuthCallback;
