import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Tab,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";
import { DataGrid } from "@mui/x-data-grid";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const ShareholderEdit = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [genderOptions, setGenderOptions] = useState([]);
  const [sharecategoryOptions, setShareCategoryOptions] = useState([]);
  const [shareholderData, setShareholderData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentValues, setCurrentValues] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [associatedEvents, setAssociatedEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const [shareholderResponse, dropdownResponse, eventResponse] =
          await Promise.all([
            axios.get(`${apiUrl}/api/shareholders/${id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
            axios.get(`${apiUrl}/api/dropdowns/`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
            axios.get(`${apiUrl}/api/shareholder-event/shareholder/${id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          ]);

        const shareholder = shareholderResponse.data;
        if (shareholder.birth_date) {
          shareholder.birth_date = format(
            parseISO(shareholder.birth_date),
            "yyyy-MM-dd"
          );
        }
        if (shareholder.death_date) {
          shareholder.death_date = format(
            parseISO(shareholder.death_date),
            "yyyy-MM-dd"
          );
        }
        if (shareholder.address_id) {
          const addrResponse = await axios.get(
            `${apiUrl}/api/addresses/${shareholder.address_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const address = addrResponse.data;
          setShareholderData({
            ...shareholder,
            ...address,
            id: shareholder.id,
            address_id: shareholder.address_id,
            sharecategory: shareholder.sharecategory || "",
            is_beneficaryofbeneficialowner:
              shareholder.is_beneficaryofbeneficialowner || false,
            is_deceased: shareholder.is_deceased || false,
            is_staff: shareholder.is_staff || false,
            is_trustee: shareholder.is_trustee || false,
            given_names: shareholder.given_names || "",
          });
        } else {
          setShareholderData({
            ...shareholder,
            address_id: null,
            sharecategory: shareholder.sharecategory || "",
            is_beneficaryofbeneficialowner:
              shareholder.is_beneficaryofbeneficialowner || false,
            is_deceased: shareholder.is_deceased || false,
            is_staff: shareholder.is_staff || false,
            is_trustee: shareholder.is_trustee || false,
            given_names: shareholder.given_names || "",
          });
        }

        const genderOptions = dropdownResponse.data.filter(
          (option) => option.type === "gender"
        );
        const shareCategoryOptions = dropdownResponse.data.filter(
          (option) => option.type === "sharecategory"
        );

        setGenderOptions(genderOptions);
        setShareCategoryOptions(shareCategoryOptions);

        if (
          !genderOptions.some(
            (option) => option.display_text === shareholder.gender
          )
        ) {
          shareholder.gender = "";
        }

        if (
          !shareCategoryOptions.some(
            (option) => option.value === shareholder.sharecategory
          )
        ) {
          shareholder.sharecategory = "";
        }

        setAssociatedEvents(eventResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, instance]);

  const validationSchema = yup.object({
    first_name: yup.string().required("First name is required"),
    middle_name: yup.string().nullable(),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email address").nullable(),
    phone: yup.string().nullable(),
    sharecategory: yup.string().nullable(),
    is_beneficialowneroutright: yup.boolean(),
    is_beneficialownerofwhanautrust: yup.boolean(),
    is_beneficaryofwhanautrust: yup.boolean(),
    is_beneficaryofbeneficialowner: yup.boolean(),
    gender: yup.string().nullable(),
    birth_date: yup.date().nullable().notRequired(),
    is_deceased: yup.boolean(),
    death_date: yup.date().nullable().notRequired(),
    ird_number: yup.string().nullable(),
    bank: yup.string().nullable(),
    bank_accountname: yup.string().nullable(),
    bank_accountnumber: yup.string().nullable(),
    is_staff: yup.boolean(),
    is_trustee: yup.boolean(),
    iwi: yup.string().nullable(),
    hapu: yup.string().nullable(),
    marae: yup.string().nullable(),
    mlc_shareholderno: yup.string().nullable(),
    mlc_shareholdername: yup.string().nullable(),
    mlc_shareholding: yup.string().nullable(),
    mlc_ownershiptype: yup.string().nullable(),
    mlc_authority: yup.string().nullable(),
    mlc_ratio: yup.string().nullable(),
    notes: yup.string().nullable(),
    street_address: yup.string().nullable(),
    street_address2: yup.string().nullable(),
    city_town: yup.string().nullable(),
    area_region: yup.string().nullable(),
    postal_code: yup.string().nullable(),
    country: yup.string().nullable(),
  });

  const handleFormSubmit = (values) => {
    setOpenConfirm(true);
    setCurrentValues(values);
  };

  const confirmSave = async () => {
    if (!currentValues) {
      enqueueSnackbar("No data to save, please check the form fields.", {
        variant: "error",
      });
      return;
    }

    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.put(`${apiUrl}/api/shareholders/${id}`, currentValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar("Shareholder updated successfully.", {
        variant: "success",
      });
      navigate("/shareholders");
    } catch (error) {
      enqueueSnackbar("Failed to update.", { variant: "error" });
      console.error("Update error:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="EDIT SHAREHOLDER"
          subtitle="Update Shareholder Details"
        />
      </Box>

      <Box sx={{ width: "100%", mt: 5, mb: 6 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab label="Shareholder Details" />
          <Tab label="Events" />
          <Tab label="Documents" />
        </Tabs>
      </Box>

      {selectedTab === 0 && shareholderData && (
        <Box sx={{ width: "60%", margin: "auto" }}>
          <Formik
            enableReinitialize
            initialValues={shareholderData}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Personal Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    required
                    label="First Name"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.first_name && Boolean(errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Middle Name"
                    name="middle_name"
                    value={values.middle_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.middle_name && Boolean(errors.middle_name)}
                    helperText={touched.middle_name && errors.middle_name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    required
                    label="Last Name"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.last_name && Boolean(errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Given Names"
                    name="given_names"
                    multiline
                    minRows={3}
                    value={values.given_names}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.given_names && Boolean(errors.given_names)}
                    helperText={
                      (touched.given_names && errors.given_names) ||
                      "Separate multiple given names using commas"
                    }
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    label="Date of birth"
                    name="birth_date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.birth_date || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.birth_date && Boolean(errors.birth_date)}
                    helperText={touched.birth_date && errors.birth_date}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <InputLabel
                    id="gender-label"
                    size="small"
                    sx={{
                      textAlign: "right",
                      width: "100%",
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.gender && Boolean(errors.gender)}
                    helperText={touched.gender && errors.gender}
                    sx={{ gridColumn: "span 1" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {genderOptions.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.display_text}
                      >
                        {option.display_text}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Address
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    name="street_address"
                    value={values.street_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.street_address && Boolean(errors.street_address)
                    }
                    helperText={touched.street_address && errors.street_address}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    name="street_address2"
                    value={values.street_address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.street_address2 && Boolean(errors.street_address2)
                    }
                    helperText={
                      touched.street_address2 && errors.street_address2
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="City / Town"
                    name="city_town"
                    value={values.city_town}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city_town && Boolean(errors.city_town)}
                    helperText={touched.city_town && errors.city_town}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Postal Code"
                    name="postal_code"
                    value={values.postal_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.postal_code && Boolean(errors.postal_code)}
                    helperText={touched.postal_code && errors.postal_code}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.country && Boolean(errors.country)}
                    helperText={touched.country && errors.country}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Contact Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Whakapapa
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Iwi"
                    name="iwi"
                    value={values.iwi}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.iwi && Boolean(errors.iwi)}
                    helperText={touched.iwi && errors.iwi}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Hapu"
                    name="hapu"
                    value={values.hapu}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.hapu && Boolean(errors.hapu)}
                    helperText={touched.hapu && errors.hapu}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Marae"
                    name="marae"
                    value={values.marae}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.marae && Boolean(errors.marae)}
                    helperText={touched.marae && errors.marae}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Shareholder Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Shareholder Number"
                    name="mlc_shareholderno"
                    value={values.mlc_shareholderno}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.mlc_shareholderno &&
                      Boolean(errors.mlc_shareholderno)
                    }
                    helperText={
                      touched.mlc_shareholderno && errors.mlc_shareholderno
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Shareholder Name"
                    name="mlc_shareholdername"
                    value={values.mlc_shareholdername}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.mlc_shareholdername &&
                      Boolean(errors.mlc_shareholdername)
                    }
                    helperText={
                      touched.mlc_shareholdername && errors.mlc_shareholdername
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Shareholding"
                    name="mlc_shareholding"
                    value={values.mlc_shareholding}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.mlc_shareholding &&
                      Boolean(errors.mlc_shareholding)
                    }
                    helperText={
                      touched.mlc_shareholding && errors.mlc_shareholding
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <InputLabel
                    id="sharecategory-label"
                    size="small"
                    sx={{
                      textAlign: "right",
                      width: "100%",
                    }}
                  >
                    Shareholder Category
                  </InputLabel>
                  <Select
                    labelId="sharecategory-label"
                    id="sharecategory"
                    name="sharecategory"
                    value={values.sharecategory}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.sharecategory && Boolean(errors.sharecategory)
                    }
                    helperText={touched.sharecategory && errors.sharecategory}
                    sx={{ gridColumn: "span 1" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {sharecategoryOptions.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.value}
                      >
                        {option.display_text}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    fullWidth
                    label="Ownership Type"
                    name="mlc_ownershiptype"
                    value={values.mlc_ownershiptype}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.mlc_ownershiptype &&
                      Boolean(errors.mlc_ownershiptype)
                    }
                    helperText={
                      touched.mlc_ownershiptype && errors.mlc_ownershiptype
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Authority"
                    name="mlc_authority"
                    value={values.mlc_authority}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.mlc_authority && Boolean(errors.mlc_authority)
                    }
                    helperText={touched.mlc_authority && errors.mlc_authority}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Ratio"
                    name="mlc_ratio"
                    value={values.mlc_ratio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.mlc_ratio && Boolean(errors.mlc_ratio)}
                    helperText={touched.mlc_ratio && errors.mlc_ratio}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Beneficary Details
                  </FormLabel>
                  <FormGroup sx={{ gridColumn: "span 2" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_beneficialowneroutright}
                          onChange={handleChange}
                          name="is_beneficialowneroutright"
                        />
                      }
                      label="Beneficial owner outright"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_beneficialownerofwhanautrust}
                          onChange={handleChange}
                          name="is_beneficialownerofwhanautrust"
                        />
                      }
                      label="Beneficial owner of whanau trust"
                    />
                  </FormGroup>
                  <FormGroup sx={{ gridColumn: "span 2" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_beneficaryofwhanautrust}
                          onChange={handleChange}
                          name="is_beneficaryofwhanautrust"
                        />
                      }
                      label="Beneficary of whanau trust"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_beneficaryofbeneficialowner}
                          onChange={handleChange}
                          name="is_beneficaryofbeneficialowner"
                        />
                      }
                      label="Beneficary of beneficial owner"
                    />
                  </FormGroup>
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Financial Details
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="IRD Number"
                    name="ird_number"
                    value={values.ird_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ird_number && Boolean(errors.ird_number)}
                    helperText={touched.ird_number && errors.ird_number}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Bank"
                    name="bank"
                    value={values.bank}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.bank && Boolean(errors.bank)}
                    helperText={touched.bank && errors.bank}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Bank Account Name"
                    name="bank_accountname"
                    value={values.bank_accountname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.bank_accountname &&
                      Boolean(errors.bank_accountname)
                    }
                    helperText={
                      touched.bank_accountname && errors.bank_accountname
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Bank Account Number"
                    name="bank_accountnumber"
                    value={values.bank_accountnumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.bank_accountnumber &&
                      Boolean(errors.bank_accountnumber)
                    }
                    helperText={
                      touched.bank_accountnumber && errors.bank_accountnumber
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Death details if applicable
                  </FormLabel>
                  <TextField
                    fullWidth
                    label="Date of death"
                    name="death_date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.death_date || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.death_date && Boolean(errors.death_date)}
                    helperText={touched.death_date && errors.death_date}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_deceased}
                        onChange={handleChange}
                        name="is_deceased"
                      />
                    }
                    label="Deceased"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormLabel
                    component="legend"
                    sx={{ gridColumn: "span 4" }}
                  >
                    Other
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_staff}
                        onChange={handleChange}
                        name="is_staff"
                      />
                    }
                    label="Staff Member"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_trustee}
                        onChange={handleChange}
                        name="is_trustee"
                      />
                    }
                    label="Trustee"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    label="Notes"
                    name="notes"
                    multiline
                    minRows={3}
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.notes && Boolean(errors.notes)}
                    helperText={touched.notes && errors.notes}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                <Box
                  m="20px 0 0 0"
                  display="flex"
                  justifyContent="flex-end"
                  gap="10px"
                >
                  <Button
                    color="grey"
                    variant="contained"
                    onClick={() => navigate("/shareholders")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}

      {selectedTab === 1 && (
        <Box
          m="20px 0 0 0"
          height="75vh"
        >
          <DataGrid
            rows={associatedEvents}
            columns={[
              { field: "name", headerName: "Event Name", width: 200 },
              { field: "type", headerName: "Event Type", width: 150 },
              { field: "date", headerName: "Date", width: 150 },
              { field: "description", headerName: "Description", width: 400 },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </Box>
      )}

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirm(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmSave}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShareholderEdit;
