import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const ShareholderCreate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [genderOptions, setGenderOptions] = useState([]); // Used for gender dropdown
  const [sharecategoryOptions, setShareCategoryOptions] = useState([]); // Used for sharecategory dropdown

  // Populate dropdowns
  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;
        const result = await axios.get(`${apiUrl}/api/dropdowns/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setGenderOptions(
          result.data.filter((option) => option.type === "gender")
        );
        setShareCategoryOptions(
          result.data.filter((option) => option.type === "sharecategory")
        );
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchData();
  }, [instance]);

  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
    sharecategory: "",
    is_beneficialowneroutright: false,
    is_beneficialownerofwhanautrust: false,
    is_beneficaryofwhanautrust: false,
    is_beneficaryofbeneficialowner: false,
    gender: "",
    birth_date: "",
    is_deceased: false,
    death_date: "",
    ird_number: "",
    bank: "",
    bank_accountname: "",
    bank_accountnumber: "",
    is_staff: false,
    is_trustee: false,
    iwi: "",
    hapu: "",
    marae: "",
    mlc_shareholderno: "",
    mlc_shareholdername: "",
    mlc_shareholding: "",
    mlc_ownershiptype: "",
    mlc_authority: "",
    mlc_ratio: "",
    notes: "",
    street_address: "",
    street_address2: "",
    city_town: "",
    area_region: "",
    postal_code: "",
    country: "",
    given_names: "",
  };

  const validationSchema = yup.object({
    first_name: yup.string().required("First name is required"),
    middle_name: yup.string(),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email("invalid email address"),
    phone: yup.string(),
    sharecategory: yup.string(),
    is_beneficialowneroutright: yup.boolean(),
    is_beneficialownerofwhanautrust: yup.boolean(),
    is_beneficaryofwhanautrust: yup.boolean(),
    is_beneficaryofbeneficialowner: yup.boolean(),
    gender: yup.string(),
    birth_date: yup.date().nullable().notRequired(),
    is_deceased: yup.boolean(),
    death_date: yup.date().nullable().notRequired(),
    ird_number: yup.string(),
    bank: yup.string(),
    bank_accountname: yup.string(),
    bank_accountnumber: yup.string(),
    is_staff: yup.boolean(),
    is_trustee: yup.boolean(),
    iwi: yup.string(),
    hapu: yup.string(),
    marae: yup.string(),
    mlc_shareholderno: yup.string(),
    mlc_shareholdername: yup.string(),
    mlc_shareholding: yup.string(),
    mlc_ownershiptype: yup.string(),
    mlc_authority: yup.string(),
    mlc_ratio: yup.string(),
    notes: yup.string(),
    street_address: yup.string(),
    street_address2: yup.string(),
    city_town: yup.string(),
    area_region: yup.string(),
    postal_code: yup.string(),
    country: yup.string(),
    given_names: yup.string(),
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      // Check if date values are empty and convert them to null
      const adjustedValues = {
        ...values,
        given_names: values.given_names.split(",").map((name) => name.trim()), // splits the string by commas and trims whitespace
        birth_date: values.birth_date ? values.birth_date : null,
        death_date: values.death_date ? values.death_date : null,
      };

      await axios.post(`${apiUrl}/api/shareholders`, adjustedValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      enqueueSnackbar("Shareholder created successfully.", {
        variant: "success",
      });
      resetForm();
      navigate("/shareholders");
    } catch (error) {
      enqueueSnackbar("Failed to create shareholder.", { variant: "error" });
      console.error("Create error:", error);
    }
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="CREATE SHAREHOLDER"
          subtitle="Create a New Shareholder"
        />
      </Box>

      <Box
        sx={{
          width: "60%",
          margin: "auto", // Centers the grid horizontally
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Personal Details
                </FormLabel>
                <TextField
                  fullWidth
                  required
                  label="First Name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.first_name && Boolean(errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Middle Name"
                  name="middle_name"
                  value={values.middle_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.middle_name && Boolean(errors.middle_name)}
                  helperText={touched.middle_name && errors.middle_name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  required
                  label="Last Name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.last_name && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Given Names"
                  name="given_names"
                  multiline
                  minRows={3}
                  value={values.given_names}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.given_names && Boolean(errors.given_names)}
                  helperText={
                    touched.first_name && errors.first_name
                      ? `${errors.first_name} (Enter your first name)`
                      : "Separate multiple given names using commas"
                  }
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  label="Date of birth"
                  name="birth_date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.birth_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.birth_date && Boolean(errors.birth_date)}
                  helperText={touched.birth_date && errors.birth_date}
                  sx={{ gridColumn: "span 2" }}
                />
                <InputLabel
                  id="gender-label"
                  size="small"
                  sx={{
                    textAlign: "right",
                    width: "100%",
                  }}
                >
                  Gender
                </InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.gender && Boolean(errors.gender)}
                  helperText={touched.gender && errors.gender}
                  sx={{ gridColumn: "span 1" }}
                >
                  {genderOptions.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.display_text}
                    >
                      {option.display_text}
                    </MenuItem>
                  ))}
                </Select>
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Address
                </FormLabel>
                <TextField
                  fullWidth
                  label="Address Line 1"
                  name="street_address"
                  value={values.street_address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.street_address && Boolean(errors.street_address)
                  }
                  helperText={touched.street_address && errors.street_address}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Address Line 2"
                  name="street_address2"
                  value={values.street_address2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.street_address2 && Boolean(errors.street_address2)
                  }
                  helperText={touched.street_address2 && errors.street_address2}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="City / Town"
                  name="city_town"
                  value={values.city_town}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city_town && Boolean(errors.city_town)}
                  helperText={touched.city_town && errors.city_town}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Postal Code"
                  name="postal_code"
                  value={values.postal_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.postal_code && Boolean(errors.postal_code)}
                  helperText={touched.postal_code && errors.postal_code}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                  sx={{ gridColumn: "span 2" }}
                />
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Contact Details
                </FormLabel>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  sx={{ gridColumn: "span 2" }}
                />
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Whakapapa
                </FormLabel>
                <TextField
                  fullWidth
                  label="Iwi"
                  name="iwi"
                  value={values.iwi}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.iwi && Boolean(errors.iwi)}
                  helperText={touched.iwi && errors.iwi}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Hapu"
                  name="hapu"
                  value={values.hapu}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.hapu && Boolean(errors.hapu)}
                  helperText={touched.hapu && errors.hapu}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Marae"
                  name="marae"
                  value={values.marae}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.marae && Boolean(errors.marae)}
                  helperText={touched.marae && errors.marae}
                  sx={{ gridColumn: "span 2" }}
                />
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Shareholder Details
                </FormLabel>
                <TextField
                  fullWidth
                  label="Shareholder Number"
                  name="mlc_shareholderno"
                  value={values.mlc_shareholderno}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.mlc_shareholderno &&
                    Boolean(errors.mlc_shareholderno)
                  }
                  helperText={
                    touched.mlc_shareholderno && errors.mlc_shareholderno
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Shareholder Name"
                  name="mlc_shareholdername"
                  value={values.mlc_shareholdername}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.mlc_shareholdername &&
                    Boolean(errors.mlc_shareholdername)
                  }
                  helperText={
                    touched.mlc_shareholdername && errors.mlc_shareholdername
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Shareholding"
                  name="mlc_shareholding"
                  value={values.mlc_shareholding}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.mlc_shareholding && Boolean(errors.mlc_shareholding)
                  }
                  helperText={
                    touched.mlc_shareholding && errors.mlc_shareholding
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <InputLabel
                  id="sharecategory-label"
                  size="small"
                  sx={{
                    textAlign: "right",
                    width: "100%",
                  }}
                >
                  Shareholder Category
                </InputLabel>
                <Select
                  labelId="sharecategory-label"
                  id="sharecategory"
                  name="sharecategory"
                  value={values.sharecategory}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.sharecategory && Boolean(errors.sharecategory)}
                  helperText={touched.sharecategory && errors.sharecategory}
                  sx={{ gridColumn: "span 1" }}
                >
                  {sharecategoryOptions.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.value}
                    >
                      {option.display_text}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  fullWidth
                  label="Ownership Type"
                  name="mlc_ownershiptype"
                  value={values.mlc_ownershiptype}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.mlc_ownershiptype &&
                    Boolean(errors.mlc_ownershiptype)
                  }
                  helperText={
                    touched.mlc_ownershiptype && errors.mlc_ownershiptype
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Authority"
                  name="mlc_authority"
                  value={values.mlc_authority}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.mlc_authority && Boolean(errors.mlc_authority)}
                  helperText={touched.mlc_authority && errors.mlc_authority}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Ratio"
                  name="mlc_ratio"
                  value={values.mlc_ratio}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.mlc_ratio && Boolean(errors.mlc_ratio)}
                  helperText={touched.mlc_ratio && errors.mlc_ratio}
                  sx={{ gridColumn: "span 2" }}
                />
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Beneficary Details
                </FormLabel>
                <FormGroup sx={{ gridColumn: "span 2" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_beneficialowneroutright}
                        onChange={handleChange}
                        name="is_beneficialowneroutright"
                      />
                    }
                    label="Beneficial owner outright"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_beneficialownerofwhanautrust}
                        onChange={handleChange}
                        name="is_beneficialownerofwhanautrust"
                      />
                    }
                    label="Beneficial owner of whanau trust"
                  />
                </FormGroup>
                <FormGroup sx={{ gridColumn: "span 2" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_beneficaryofwhanautrust}
                        onChange={handleChange}
                        name="is_beneficaryofwhanautrust"
                      />
                    }
                    label="Beneficary of whanau trust"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_beneficaryofbeneficialowner}
                        onChange={handleChange}
                        name="is_beneficaryofbeneficialowner"
                      />
                    }
                    label="Beneficary of beneficial owner"
                  />
                </FormGroup>
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Financial Details
                </FormLabel>
                <TextField
                  fullWidth
                  label="IRD Number"
                  name="ird_number"
                  value={values.ird_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ird_number && Boolean(errors.ird_number)}
                  helperText={touched.ird_number && errors.ird_number}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Bank"
                  name="bank"
                  value={values.bank}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.bank && Boolean(errors.bank)}
                  helperText={touched.bank && errors.bank}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Bank Account Name"
                  name="bank_accountname"
                  value={values.bank_accountname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.bank_accountname && Boolean(errors.bank_accountname)
                  }
                  helperText={
                    touched.bank_accountname && errors.bank_accountname
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Bank Account Number"
                  name="bank_accountnumber"
                  value={values.ird_nbank_accountnumberumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.bank_accountnumber &&
                    Boolean(errors.bank_accountnumber)
                  }
                  helperText={
                    touched.bank_accountnumber && errors.bank_accountnumber
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Death details if applicable
                </FormLabel>
                <TextField
                  fullWidth
                  label="Date of death"
                  name="death_date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.death_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.death_date && Boolean(errors.death_date)}
                  helperText={touched.death_date && errors.death_date}
                  sx={{ gridColumn: "span 2" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.is_deceased}
                      onChange={handleChange}
                      name="is_deceased"
                    />
                  }
                  label="Deceased"
                  sx={{ gridColumn: "span 2" }}
                />
                <FormLabel
                  component="legend"
                  sx={{ gridColumn: "span 4" }}
                >
                  Other
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.is_staff}
                      onChange={handleChange}
                      name="is_staff"
                    />
                  }
                  label="Staff Member"
                  sx={{ gridColumn: "span 2" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.is_trustee}
                      onChange={handleChange}
                      name="is_trustee"
                    />
                  }
                  label="Trustee"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  label="Notes"
                  name="notes"
                  multiline
                  minRows={3}
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.notes && Boolean(errors.notes)}
                  helperText={touched.notes && errors.notes}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              <Box
                m="20px 0 0 0"
                display="flex"
                justifyContent="flex-end"
                gap="10px"
              >
                <Button
                  color="grey"
                  variant="contained"
                  onClick={() => navigate("/shareholders")}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                >
                  Create Shareholder
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ShareholderCreate;
