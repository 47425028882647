import {
  Box,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/authConfig";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { useSnackbar } from "notistack";

const apiUrl = process.env.REACT_APP_OTSG_API_ENDPOINT;

const GrantRecipientView = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { instance } = useMsal();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  const [genderOptions, setGenderOptions] = useState([]);
  const [grantRecipientData, setGrantRecipientData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const account = instance.getActiveAccount();
      if (!account) {
        console.error("No active account! Please log in.");
        return;
      }

      const request = {
        ...loginRequest,
        account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const [grantRecipientResponse, dropdownResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/grantrecipients/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${apiUrl}/api/dropdowns/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        const grantRecipient = grantRecipientResponse.data;
        if (grantRecipient.birth_date) {
          grantRecipient.birth_date = format(
            parseISO(grantRecipient.birth_date),
            "yyyy-MM-dd"
          );
        }

        setGrantRecipientData(grantRecipient);

        const genderOptions = dropdownResponse.data.filter(
          (option) => option.type === "gender"
        );

        setGenderOptions(genderOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, instance]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEdit = () => {
    navigate(`/grants/edit/${id}`);
  };

  const handleClickOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  const handleDelete = async () => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No active account! Please log in.");
      return;
    }

    const request = {
      ...loginRequest,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      await axios.delete(`${apiUrl}/api/grantrecipients/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar("Grant deleted successfully.", {
        variant: "success",
      });
      navigate("/grants");
      handleClose();
    } catch (error) {
      enqueueSnackbar("Failed to delete grant.", { variant: "error" });
      handleClose();
    }
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          title="VIEW GRANT RECIPIENT"
          subtitle="View Grant Recipient Details"
        />
      </Box>

      <Box sx={{ width: "100%", mt: 5, mb: 6 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab label="Grant Recipient Details" />
        </Tabs>
      </Box>

      {selectedTab === 0 && grantRecipientData && (
        <Box sx={{ width: "60%", margin: "auto" }}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gridColumn: "span 4",
              }}
            >
              <IconButton
                style={{ color: theme.palette.edit.main }}
                onClick={handleEdit}
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton
                style={{ color: theme.palette.error.main }}
                onClick={handleClickOpen}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Box>

            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Personal Details
            </FormLabel>
            <TextField
              fullWidth
              label="First Name"
              value={grantRecipientData.first_name}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Middle Name"
              value={grantRecipientData.middle_name || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Last Name"
              value={grantRecipientData.last_name}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Date of Birth"
              type="date"
              value={grantRecipientData.birth_date || ""}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Gender"
              value={grantRecipientData.gender || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 1", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Address
            </FormLabel>
            <TextField
              fullWidth
              label="Address Line 1"
              value={grantRecipientData.street_address || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Address Line 2"
              value={grantRecipientData.street_address2 || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="City / Town"
              value={grantRecipientData.city_town || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Postal Code"
              value={grantRecipientData.postal_code || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Country"
              value={grantRecipientData.country || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Contact Details
            </FormLabel>
            <TextField
              fullWidth
              label="Email"
              value={grantRecipientData.email || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Phone"
              value={grantRecipientData.phone || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Financial Details
            </FormLabel>
            <TextField
              fullWidth
              label="IRD Number"
              value={grantRecipientData.ird_number || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Bank"
              value={grantRecipientData.bank || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Bank Account Name"
              value={grantRecipientData.bank_accountname || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Bank Account Number"
              value={grantRecipientData.bank_accountnumber || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Whakapapa
            </FormLabel>
            <TextField
              fullWidth
              label="Iwi"
              value={grantRecipientData.iwi || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Hapu"
              value={grantRecipientData.hapu || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <TextField
              fullWidth
              label="Marae"
              value={grantRecipientData.marae || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormLabel
              component="legend"
              sx={{ gridColumn: "span 4" }}
            >
              Student Details
            </FormLabel>
            <TextField
              fullWidth
              label="Student ID"
              value={grantRecipientData.student_id || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ gridColumn: "span 2", bgcolor: "#e4eeef" }}
            />
            <FormGroup sx={{ gridColumn: "span 2" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={grantRecipientData.consider_workingnorthland}
                    disabled
                  />
                }
                label="Consider working in Northland"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={grantRecipientData.consider_researchngapuhiissues}
                    disabled
                  />
                }
                label="Consider researching Ngāpuhi issues"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={grantRecipientData.consider_postgradstudy}
                    disabled
                  />
                }
                label="Consider postgraduate study"
              />
            </FormGroup>
            <TextField
              fullWidth
              label="Areas of Interest"
              value={grantRecipientData.areas_of_interest || ""}
              InputProps={{
                readOnly: true,
              }}
              multiline
              minRows={3}
              sx={{ gridColumn: "span 4", bgcolor: "#e4eeef" }}
            />
          </Box>
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">WARNING!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this grant recipient?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDelete}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GrantRecipientView;
